import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';
import './MarketCaps.css';

const MarketCapsDocumentation = () => {
    return (
        <div className="market-caps-documentation">
            <h1>Market Capitalization API Documentation</h1>

            {/* Overview Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Retrieving Market Capitalization Data</Card.Title>
                    <p>
                        The <strong>/financials/market_caps</strong> endpoint provides access to market capitalization data
                        for various stock symbols. You can filter results using <code>symbol</code>, <code>start_date</code>, and <code>end_date</code>.
                    </p>
                    <p>
                        <strong>Note:</strong> The <code>symbol</code> parameter is optional. If not provided, data for all available symbols will be returned.
                    </p>
                </Card.Body>
            </Card>

            {/* Endpoint Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Endpoint</Card.Title>
                    <ul>
                        <li>
                            <code>/financials/market_caps</code> - Retrieve market capitalization records for stocks.
                        </li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Query Parameters Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Query Parameters</Card.Title>
                    <p>Below are the query parameters supported by the <code>/financials/market_caps</code> endpoint:</p>
                    <ul>
                        <li>
                            <strong>symbol</strong> (optional): Stock symbol to filter data (e.g., "AAPL").
                        </li>
                        <li>
                            <strong>start_date</strong> (optional): Start date for filtering data in YYYY-MM-DD format.
                        </li>
                        <li>
                            <strong>end_date</strong> (optional): End date for filtering data in YYYY-MM-DD format.
                        </li>
                        <li>
                            <strong>limit</strong> (optional, default=1000): Number of most recent records to retrieve.
                        </li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Example API Requests */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Example API Requests</Card.Title>
                    <Tabs defaultActiveKey="curl" id="api-examples-tabs">

                        {/* cURL Examples */}
                        <Tab eventKey="curl" title="cURL">
                            <h4>Fetch Market Cap Data for a Symbol (e.g., "AAPL")</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials/market_caps?symbol=AAPL" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>

                            <h4>Filter by Date Range</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials/market_caps?symbol=AAPL&start_date=2023-01-01&end_date=2023-12-31" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>
                        </Tab>

                        {/* Python Examples */}
                        <Tab eventKey="python" title="Python">
                            <h4>Fetch Market Cap Data for a Symbol (e.g., "AAPL")</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/market_caps'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>

                            <h4>Filter by Date Range</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/market_caps'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'start_date': '2023-01-01', 'end_date': '2023-12-31'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                        </Tab>

                        {/* JavaScript Examples */}
                        <Tab eventKey="javascript" title="JavaScript">
                            <h4>Fetch Market Cap Data for a Symbol (e.g., "AAPL")</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/market_caps';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>

                            <h4>Filter by Date Range</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/market_caps';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL', start_date: '2023-01-01', end_date: '2023-12-31' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        </div>
    );
};

export default MarketCapsDocumentation;
