import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import './Header.css';
import { logout } from '../Auth/authUtils'; // Import the logout function

const Header = () => {
    const [hoveredSource, setHoveredSource] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate

    const dataSources = [
        {
            name: 'Home',
            path: '/home'
        },
        // {name: 'Stocks', path:'/stocks'},
        // {
        //     name: 'ETFs',
        //     path: '/etfs',
        // },
        {name: 'Data Catalogue', path :'/data-catalogue'},
        {
            name: 'API Documentation',
            entries: [
                { name: 'Basics', path: '/api-basics' },
                { name: 'Fundamentals', path: '/fundamentals-docs'},
                { name: 'Historical Prices', path: '/historical-prices'},
                { name: 'Metadata', path: '/metadata-docs'}
            ]
        }
    ];

    const handleMouseEnter = (name) => {
        setHoveredSource(name);
    };

    const handleMouseLeave = () => {
        setHoveredSource(null);
    };

    return (
        <header className="app-header">
            <div className="logo">
            </div>
            <nav className="navigation">
                <ul className="side-panel">
                    {dataSources.map((source, index) => (
                        <li
                            key={index}
                            className="data-source-item"
                            onMouseEnter={() => handleMouseEnter(source.name)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="data-source-name">
                                {source.path ? (
                                    <Link to={source.path}>{source.name}</Link>
                                ) : (
                                    source.name
                                )}
                            </div>
                            {hoveredSource === source.name && source.subEntries && (
                                <ul className="data-entry-list">
                                    {source.subEntries.map((subEntry, subEntryIndex) => (
                                        <li key={subEntryIndex}>
                                            <Link to={subEntry.path}>{subEntry.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {hoveredSource === source.name && source.entries && (
                                <ul className="data-entry-list">
                                    {source.entries.map((entry, entryIndex) => (
                                        <li key={entryIndex}>
                                            <Link to={entry.path}>{entry.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
                <nav className="navigation">
                    <ul className="nav-links">
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/account">Account</Link></li>
                        <li><a href="#" onClick={() => logout(navigate)}>Log Out</a></li>
                    </ul>
                </nav>
            </nav>
        </header>
    );
};

export default Header;
