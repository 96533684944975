import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import HomePage from "./Views/HomePage/HomePage";
import Login from './Components/Auth/Login';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import IntroductionSection from './Components/Docs/APIInterface';
import About from './Components/Docs/About';
import Layout from './Components/Layout/Layout';
import Account from './Views/Account/Account';
import FundamentalsDocumentation from "./Components/Docs/Fundamentals";
import Stocks from "./Views/Stocks/Stocks";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MetadataDocumentation from "./Components/Docs/Metadata";
import DataCatalogue from "./Views/DataCatalogue/Catalogue";
import MarketCapsDocumentation from "./Components/Docs/MarketCaps";

interface LoginProps {
    onLogin: () => void;
}

// Initialize the QueryClient instance
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,                 // Retry failed queries once
            staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
            // cacheTime: 1000 * 60 * 10 // Keep data in cache for 10 minutes
        },
    },
});

const App: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!localStorage.getItem('access_token'));
    const [userEmail, setUserEmail] = useState<string | null>(null);

    useEffect(() => {
        const email = localStorage.getItem('user_email');
        setUserEmail(email);
    }, []);

    const handleLogin = () => {
        setIsLoggedIn(true);
        const email = localStorage.getItem('user_email');
        setUserEmail(email);
    };

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Routes>
                    <Route path="/" element={<Navigate to="/home" replace />} />
                    <Route
                        path="/login"
                        element={
                            <Layout>
                                <Login onLogin={handleLogin} />
                            </Layout>
                        }
                    />
                    <Route
                        path="/home"
                        element={
                            <Layout>
                                <HomePage />
                            </Layout>
                        }
                    />
                    <Route
                        path="/api-basics"
                        element={
                            <Layout>
                                <IntroductionSection />
                            </Layout>
                        }
                    />
                    <Route
                        path="/fundamentals-docs"
                        element={
                            <Layout>
                                <FundamentalsDocumentation />
                            </Layout>
                        }
                    />

                    <Route
                        path="/historical-prices"
                        element={
                            <Layout>
                                <MarketCapsDocumentation />
                            </Layout>
                        }
                    />
                    <Route
                        path="/metadata-docs"
                        element={
                            <Layout>
                                <MetadataDocumentation />
                            </Layout>
                        }
                    />
                    <Route
                        path="/data-catalogue"
                        element={
                            <Layout>
                                <DataCatalogue />
                            </Layout>
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <Layout>
                                <About />
                            </Layout>
                        }
                    />

                    {/* Protected routes requiring login */}
                    {isLoggedIn ? (
                        <>
                            <Route
                                path="/stocks"
                                element={
                                    <ProtectedRoute>
                                        <Layout>
                                            <Stocks />
                                        </Layout>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/account"
                                element={
                                    <ProtectedRoute>
                                        <Layout>
                                            <Account />
                                        </Layout>
                                    </ProtectedRoute>
                                }
                            />
                        </>
                    ) : (
                        // Redirect all other routes to /login if not logged in
                        <Route path="*" element={<Navigate to="/login" />} />
                    )}
                </Routes>
            </Router>
        </QueryClientProvider>
    );
};

export default App;
